import * as $ from "jquery";
window.jQuery = $;
window.$ = $;

import 'bootstrap';
import 'bootstrap-datepicker';

import L from 'leaflet';
import 'leaflet-defaulticon-compatibility';
import 'leaflet.markercluster';
import 'mapbox-gl';
import 'mapbox-gl-leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';

import 'jquery-colorbox';

import PhotoSwipe from 'photoswipe'
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'

import './jquery.magstripe.js';

var tileUrl = 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoic3VzdGFpbmluZ3RlY2hub2xvZ2llcyIsImEiOiJjazc2Z2o5eTkwaWh1M2VwZ3JhZWs0NjQzIn0.bLSyhRdeusqC0IsZllu2cA';
var attribution = '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>';

function initializeMap(mapElementId, center) {
  var options = {
    zoom: 14,
    maxZoom: 18,
    scrollWheelZoom: false,
    center: center
  };
  var map = L.map(mapElementId, options);
  var gl = L.mapboxGL({
      accessToken: 'pk.eyJ1Ijoic3VzdGFpbmluZ3RlY2hub2xvZ2llcyIsImEiOiJjazc2Z2o5eTkwaWh1M2VwZ3JhZWs0NjQzIn0.bLSyhRdeusqC0IsZllu2cA',
      style: 'mapbox://styles/mapbox/streets-v11'
  }).addTo(map);
  map.attributionControl.setPrefix('© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>');
  return map;
}

function mapAddress(mapElementId, address) {

  var provider = new OpenStreetMapProvider();

  provider
    .search({ query: address })
    .then(function(results) {
      var result = results[0];

      if (typeof result === 'undefined') {
        var link = provider.endpoint({ query: address });
        link = link.replace('format=json&', '');
        $('#' + mapElementId).html(
          'Unable to locate address for mapping:<br/><br/>' +
          '<a href="' + link + '">' + address + '</a>');
        return;
      }

      var map = initializeMap(mapElementId, [result.y, result.x]);

      L.marker([result.y, result.x]).addTo(map);

    });

}
window.mapAddress = mapAddress;


function mapCoordinates(mapElementId, lat, lng) {
  var map = initializeMap(mapElementId, [lat, lng]);
  L.marker([lat, lng]).addTo(map);
}
window.mapCoordinates = mapCoordinates;


function mapClustered(mapElementId, coordinateList) {
  if (coordinateList.length === 0) {
    $('#map').hide();
    return;
  }
  var validLocations = $.grep(coordinateList, function (obj, i) {
    return obj.location !== null;
  });
  var latLngs = $.map(validLocations, function (obj, i) {
    return L.latLng(obj.location.lat, obj.location.lng);
  });
  var bounds = new L.LatLngBounds(latLngs);
  var map = initializeMap(mapElementId, bounds.getCenter());
  map.fitBounds(bounds);

  var markers = L.markerClusterGroup();

  for (var i = 0; i < latLngs.length; i++) {
    var location = latLngs[i];
    var title = validLocations[i].title;
    var url = validLocations[i].url;
    var marker = L.marker(new L.LatLng(location.lat, location.lng), { title: title });
    marker.bindPopup('<a href="' + url + '">' + title + '</a>');
    markers.addLayer(marker);
  }

  map.addLayer(markers);

}
window.mapClustered = mapClustered;


function initGallery(pswp, selector) {
  var image = [];

  $(selector).each( function() {
    var $gallery = $(this),
      getItems = function() {
        var items = [];

        $gallery.find('figure').each(function() {
          var $href = $(this).find('a').attr('href'),
              $size = $(this).find('a').data('size').split('x'),
              $width = $size[0],
              $height = $size[1],
              $title = $(this).find('figcaption').html();

          var item = {
              src: $href,
              w: $width,
              h: $height,
              title: $title
          }

          items.push(item);
        });
        return items;
      }

    var items = getItems();

    $.each(items, function(index, value) {
      image[index]     = new Image();
      image[index].src = value['src'];
    });

    $gallery.on('click', 'a', function(event) {
      event.preventDefault();

      var index = $(this).closest('div').index();
      var options = {
          index: index,
          bgOpacity: 0.7,
          showHideOpacity: true
      }

      var lightBox = new PhotoSwipe(pswp, PhotoSwipeUI_Default, items, options);
      lightBox.init();
    });
  });
}
window.initGallery = initGallery;

$(function () {

  // Initialize navbar popovers
  $('.search-popover').popover({
    html: true,
    sanitize: false,
    content: function () {
      return $('#search-popover-content').html();
    }
  });
  $('.user-popover').popover({
    html: true,
    content: function () {
      return $('#user-popover-content').html();
    }
  });

  // Move sort by field, if browers supports the form attribute
  if (Modernizr.formattribute) {
    var $container = $('#sort-by-container');
    var $sortBy = $('#div_id_sort_by');
    var formId = $sortBy.closest('form').attr('id');
    $sortBy.detach();
    $sortBy.find('label').addClass('mr-2');
    $container.append($sortBy);
    $container.removeClass('d-none');
    $sortBy.find('select').attr('form', formId);
  }

  // Auto-sumbmit form when sort order is changed
  $("#id_sort_by").change(function(){
    $($(this).get(0).form).submit();
  });

  $('[data-toggle="collapse"]').click(function () {
    $('.collapse-icon').toggle();
  });

});